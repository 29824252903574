// src/components/DataAgreementLevelSelector.jsx
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { fetchDataAgreements } from '../services/dataService';

const levels = [
  { value: 'level1', label: 'Level 1' },
  { value: 'level2', label: 'Level 2' },
  { value: 'level3', label: 'Level 3' },
];

const DataAgreementLevelSelector = ({ onChange }) => {
  const [options, setOptions] = useState([]);
  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDataAgreements = async () => {
      setLoading(true);
      const data = await fetchDataAgreements();
      if (data.length === 0) {
        setError('No data agreements found.');
      } else {
        const formattedOptions = data.map((item) => ({
          value: item.dataagreementid,
          label: item['Short Title'],
          levels: [...levels], // Initialize with all levels selected
        }));
        setOptions(formattedOptions);
      }
      setLoading(false);
    };

    getDataAgreements();
  }, []);

  const handleAgreementChange = (selectedOptions) => {
    setSelectedAgreements(selectedOptions || []);
    // Transform the selected options to include levels
    const selections = (selectedOptions || []).map((option) => ({
      dataagreementid: option.value,
      shortTitle: option.label,
      levels: option.levels.map((level) => level.value),
    }));
    onChange(selections);
  };

  const handleLevelChange = (selectedLevels, index) => {
    const updatedAgreements = [...selectedAgreements];
    updatedAgreements[index].levels = selectedLevels.map((level) => level.value);
    setSelectedAgreements(updatedAgreements);
    // Transform the updated options to include levels
    const selections = updatedAgreements.map((agreement) => ({
      dataagreementid: agreement.value,
      shortTitle: agreement.label,
      levels: agreement.levels,
    }));
    onChange(selections);
  };

  if (loading) {
    return <div>Loading Data Agreements...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div>
      <Select
        options={options}
        isMulti
        onChange={handleAgreementChange}
        placeholder="Select Data Agreement(s)"
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
        }}
      />
      {selectedAgreements.map((agreement, index) => (
        <div key={agreement.value} style={{ marginTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>{agreement.label} Levels:</label>
          <Select
            options={levels}
            isMulti
            value={levels.filter((level) => agreement.levels.includes(level.value))}
            onChange={(selectedLevels) => handleLevelChange(selectedLevels, index)}
            placeholder="Select Levels"
          />
        </div>
      ))}
    </div>
  );
};

export default DataAgreementLevelSelector;
