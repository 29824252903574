// ----- admin_elements\UpdateConfirmationModal.jsx -----
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaCalendarAlt } from 'react-icons/fa';
import styles from '../styles/UpdateConfirmationModal.module.css';
import { format, parseISO } from 'date-fns';
import { getTypes } from '../services/api/index'; // Import getTypes

Modal.setAppElement('#root'); // Adjust if your root element has a different ID

const UpdateConfirmationModal = ({
  field,
  currentValue,
  newValue,
  onConfirm,
  onCancel,
}) => {
  const isDateField = field === 'start_date' || field === 'end_date';
  const isAmountField = field === 'amount';
  const isCiRefField = field === 'ci_ref';
  const isTypenameField = field === 'typename';

  const [inputValue, setInputValue] = useState(newValue);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setInputValue(newValue);

    if (isTypenameField) {
      // Fetch types when updating 'typename'
      const fetchTypes = async () => {
        const typesData = await getTypes();
        setTypes(typesData);
      };
      fetchTypes();
    }
  }, [newValue, isTypenameField]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (!inputValue) {
      alert('Please enter a new value.');
      return;
    }
    onConfirm(field, inputValue);
  };

  // Format current value if it's a date
  const formattedCurrentValue = isDateField && currentValue
    ? format(parseISO(currentValue), 'yyyy-MM-dd')
    : currentValue;

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      contentLabel="Confirm Update"
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
      closeTimeoutMS={200}
    >
      <h2>Update {field.replace('_', ' ').toUpperCase()}</h2>
      <div className={styles.modalBody}>
        <p><strong>Current Value:</strong> {formattedCurrentValue || 'N/A'}</p>
        <div className={styles.inputGroup}>
          <label htmlFor="newValue">New Value:</label>
          {isDateField ? (
            <div className={styles.inputWithIcon}>
              <FaCalendarAlt className={styles.calendarIcon} />
              <input
                id="newValue"
                type="date"
                value={inputValue}
                onChange={handleInputChange}
                className={styles.input}
                placeholder="YYYY-MM-DD"
              />
            </div>
          ) : isAmountField ? (
            <input
              id="newValue"
              type="number"
              step="0.01"
              value={inputValue}
              onChange={handleInputChange}
              className={styles.input}
              placeholder="Enter new amount"
            />
          ) : isTypenameField ? (
            <select
              id="newValue"
              value={inputValue}
              onChange={handleInputChange}
              className={styles.input}
            >
              <option value="">-- Select Type --</option>
              {types.map((type) => (
                <option key={type.typeid} value={type.typeid}>
                  {type.typename}
                </option>
              ))}
            </select>
          ) : (
            <input
              id="newValue"
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className={styles.input}
              placeholder="Enter new value"
            />
          )}
        </div>
      </div>
      <div className={styles.modalActions}>
        <button onClick={handleSubmit} className={styles.confirmButton}>
          Confirm
        </button>
        <button onClick={onCancel} className={styles.cancelButton}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default UpdateConfirmationModal;
