// src/pages/Verified.jsx
import React, { useEffect, useState, useContext } from 'react';
import { supabase } from '../services/supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const Verified = () => {
  const [message, setMessage] = useState('Verifying your email...');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      // Log the full URL
      console.log('Full URL:', window.location.href);
      console.log('Search Params:', location.search);
      console.log('Hash Params:', location.hash);

      // Parse query parameters
      const urlParams = new URLSearchParams(location.search);
      const email = urlParams.get('email');
      const token = urlParams.get('token');
      const type = urlParams.get('type');

      // Parse fragment parameters
      const hashParams = new URLSearchParams(location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      const fragmentType = hashParams.get('type');

      console.log('User Email (query):', email);
      console.log('Verification Token (query):', token);
      console.log('Verification Type (query):', type);

      console.log('Access Token (fragment):', accessToken);
      console.log('Verification Type (fragment):', fragmentType);

      // Handle email verification via query parameters
      if (email && token && type) {
        // Proceed with email verification
        const { error: verifyError } = await supabase.auth.verifyOtp({
          email: email,
          token: token,
          type: type, // 'signup'
        });

        if (verifyError) {
          console.error('Verification Error:', verifyError.message);
          setMessage(`Verification failed: ${verifyError.message}`);
          setError(verifyError.message);
          return;
        }

        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError || !user) {
          console.error('Error fetching user:', userError ? userError.message : 'No user returned');
          setMessage('Verification succeeded, but failed to fetch user details.');
          return;
        }

        setUser(user);
        setMessage('Your email has been successfully verified! Redirecting to dashboard...');
        setTimeout(() => navigate('/dashboard'), 3000);
      }
      // Handle sign-in via fragment parameters (if needed)
      else if (accessToken && fragmentType === 'signup') {
        // Optionally, sign in the user using the access token
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: hashParams.get('refresh_token'),
        });

        if (error) {
          console.error('Error setting session:', error.message);
          setMessage(`Error during sign-in: ${error.message}`);
          setError(error.message);
          return;
        }

        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError || !user) {
          console.error('Error fetching user:', userError ? userError.message : 'No user returned');
          setMessage('Sign-in succeeded, but failed to fetch user details.');
          return;
        }

        setUser(user);
        setMessage('Your email has been successfully verified and signed in! Redirecting to dashboard...');
        setTimeout(() => navigate('/dashboard'), 3000);
      }
      else {
        setMessage('Invalid or missing verification parameters.');
        setError('Invalid verification link.');
      }
    })();
  }, [location.search, location.hash, navigate, setUser]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
        <h2 className="text-2xl font-bold text-blue-600">Email Verification</h2>
        <p className="mt-4 text-gray-700">{message}</p>
        {error && <div className="mt-4 text-red-500">{error}</div>}
      </div>
    </div>
  );
};

export default Verified;
