// src/pages/Pricing.jsx
import React from 'react';

const Pricing = () => {
  return (
    <div className="container mx-auto text-center py-16">
      <h1 className="text-4xl font-bold text-blue-900 mb-8">Pricing</h1>
      <p className="text-lg text-gray-700">
        To discuss your specific needs and pricing options, please contact:
      </p>
      <p className="text-lg text-blue-800 font-bold mt-4">Email: kristian@datasolve.tech</p>
      <p className="text-lg text-gray-700 mt-2">
        We'll be happy to organize a meeting to find the best solution for your business.
      </p>
    </div>
  );
};

export default Pricing;
