// src/admin_elements/ParsedDataDisplay.jsx

import React from 'react';
import styles from '../styles/ParsedDataDisplay.module.css';

const ParsedDataDisplay = ({ sheetName, data }) => {
  if (sheetName === '__errors__') {
    // Handle and display errors if any
    return (
      <div className={styles.sheetContainer}>
        <h4>Error Details:</h4>
        <ul>
          {data.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className={styles.sheetContainer}>
        <h4>{sheetName}</h4>
        <p>No data available.</p>
      </div>
    );
  }

  return (
    <div className={styles.sheetContainer}>
      <h4>{sheetName}</h4>
      <div className={styles.tableWrapper}>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              {Object.keys(data[0] || {}).map((header, idx) => (
                <th key={idx}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((record, rowIdx) => (
              <tr key={rowIdx}>
                {Object.values(record).map((value, colIdx) => (
                  <td key={colIdx}>{value !== null && value !== undefined ? value.toString() : ''}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ParsedDataDisplay;
