// pages/Admin.jsx

import React, { useState, useEffect } from 'react';
import { fetchFactDataAgreement } from '../services/api';
import LeftPanelAccordion from '../admin_elements/LeftPanelAccordion';
import AccordionTableDetails from '../admin_elements/AccordionTableDetails';
import QuickNavigationPanel from '../admin_elements/QuickNavigationPanel';
import Calculations from '../admin_elements/Calculations';
import styles from '../styles/Admin.module.css';

const Admin = () => {
  const [factDataRows, setFactDataRows] = useState([]);
  const [selectedDataAgreementId, setSelectedDataAgreementId] = useState(null);
  const [selectedTradepersonId, setSelectedTradepersonId] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadInitialData = async () => {
    setLoading(true);
    try {
      const factDataRows = await fetchFactDataAgreement();
      setFactDataRows(factDataRows || []);

      if (factDataRows && factDataRows.length > 0) {
        setSelectedDataAgreementId(factDataRows[0].dataagreementid);
      }
    } catch (err) {
      console.error('Error loading data:', err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleDataAgreementSelect = (dataAgreementId) => {
    setSelectedDataAgreementId((prevId) => {
      if (prevId !== dataAgreementId) {
        setSelectedTradepersonId(null); // Reset tradeperson only if dataAgreementId changes
      }
      return dataAgreementId;
    });
  };

  const handleTradepersonSelect = (tradepersonId) => {
    setSelectedTradepersonId(tradepersonId);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Admin Page</h1>
      {loading && <p className={styles.loading}>Loading...</p>}

      {/* Quick Navigation Panel under nav bar */}
      <QuickNavigationPanel
        dataAgreements={factDataRows}
        onSelect={handleDataAgreementSelect}
        onTradepersonSelect={handleTradepersonSelect}
      />

      <div className={styles.mainContent}>
        {/* Left Panel (20%) */}
        <div className={styles.leftPanel}>
          <LeftPanelAccordion
            dataAgreements={factDataRows}
            selectedDataAgreementId={selectedDataAgreementId}
            onRefresh={loadInitialData}
            dataAgreementId={selectedDataAgreementId}
            onTradepersonSelect={handleTradepersonSelect}
            selectedTradepersonId={selectedTradepersonId} // Pass the selectedTradepersonId
          />
        </div>

        {/* Middle Panel (40%) */}
        <div className={styles.middlePanel}>
          <AccordionTableDetails
            dataAgreementId={selectedDataAgreementId}
            tradepersonId={selectedTradepersonId}
          />
        </div>

        {/* Right Panel (40%) */}
        <div className={styles.rightPanel}>
          <Calculations />
        </div>
      </div>
    </div>
  );
};

export default Admin;

