// services/api/insertAdminRecord.js

import { supabase } from '../supabaseClient';

/**
 * Inserts a new record into a specified table using the 'insert_admin_record' stored procedure.
 *
 * @param {object} params - The parameters for insertion.
 * @returns {Promise<{ success: boolean, message: string }>}
 */
export const insertAdminRecord = async ({
  table_name,
  dataagreementid,
  tradepersonid,
  start_date,
  end_date,
  amount,
  typeid,
  user_email,
}) => {
  console.log('insertAdminRecord called with parameters:', {
    table_name,
    dataagreementid,
    tradepersonid,
    start_date,
    end_date,
    amount,
    typeid,
    user_email,
  });

  try {
    const { data, error } = await supabase.rpc('insert_admin_record', {
      p_table_name: table_name,
      p_dataagreementid: dataagreementid,
      p_tradepersonid: tradepersonid,
      p_start_date: start_date,
      p_end_date: end_date,
      p_amount: amount,
      p_typeid: typeid,
      p_user_email: user_email,
    });

    if (error) {
      console.error('Insertion failed:', error.message);
      return { success: false, message: error.message };
    }

    console.log('Insertion successful:', data);
    return { success: true, message: data };
  } catch (err) {
    console.error('Unexpected error during insertion:', err);
    return { success: false, message: 'An unexpected error occurred.' };
  }
};
