// services/api/fetchTradepersonsByDataAgreementId.js

import { supabase } from '../supabaseClient';

/**
 * Fetches tradepersons by dataagreementid from the 'dim_tradeperson' table.
 *
 * @param {number} dataAgreementId - The Data Agreement ID.
 * @returns {Promise<Array<Object>>} - Array of tradepersons.
 */
export const fetchTradepersonsByDataAgreementId = async (dataAgreementId) => {
  try {
    const { data, error } = await supabase
      .from('dim_tradeperson')
      .select('*')
      .eq('dataagreementid', dataAgreementId);

    if (error) {
      console.error('Error fetching tradepersons:', error);
      return [];
    }

    return data;
  } catch (error) {
    console.error('Unexpected error fetching tradepersons:', error);
    return [];
  }
};
