// src/hooks/useUserEmail.js
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const useUserEmail = () => {
  const { user } = useContext(AuthContext);
  return user ? user.email : null;
};

export default useUserEmail;
