// admin_elements/FactDataAgreementPanel.jsx

import React from 'react';
import styles from '../styles/FactDataAgreementPanel.module.css';

const FactDataAgreementPanel = ({ dataAgreements, selectedDataAgreementId }) => {
  const currentRecord = dataAgreements.find(
    (item) => item.dataagreementid === selectedDataAgreementId
  );

  if (!currentRecord) {
    return <div>No Data Agreement selected.</div>;
  }

  return (
    <div className={styles.panel}>
      <h2>Data Agreement Details</h2>
      <div className={styles.recordDetails}>
        {Object.entries(currentRecord).map(([key, value]) => (
          <div key={key} className={styles.recordRow}>
            <strong>{key}</strong>: <span>{value !== null ? value.toString() : 'N/A'}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FactDataAgreementPanel;
