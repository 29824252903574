// src/services/dataService.js
import { supabase } from './supabaseClient';

/**
 * Fetches chart data from the 'vw_final_calc_v1_cached' view.
 * @returns {Array} Array of data objects.
 */
export const fetchChartData = async () => {
  const { data, error } = await supabase
    .from('vw_final_calc_v1_cached')
    .select(`
      dataagreementid,
      "Short Title",
      quarterdate,
      level1_annual_earnings_p_year,
      level2_annual_earnings_p_year,
      level3_annual_earnings_p_year,
      level1_annual_earnings_p_week,
      level2_annual_earnings_p_week,
      level3_annual_earnings_p_week,
      level1_total_labor_cost_p_y,
      level2_total_labor_cost_p_y,
      level3_total_labor_cost_p_y,
      level1_total_labor_cost_p_hour,
      level2_total_labor_cost_p_hour,
      level3_total_labor_cost_p_hour,
      level1_total_labor_cost_p_prod_hour,
      level2_total_labor_cost_p_prod_hour,
      level3_total_labor_cost_p_prod_hour
    `);

  if (error) {
    console.error('Error fetching chart data:', error.message);
    return [];
  }

  // Sort data by quarterdate for chronological order
  const sortedData = data.sort((a, b) => new Date(a.quarterdate) - new Date(b.quarterdate));

  return sortedData;
};

/**
 * Fetches data agreements from the 'vw_dataagreement' view.
 * @returns {Array} Array of data agreement objects.
 */
export const fetchDataAgreements = async () => {
  const { data, error } = await supabase
    .from('vw_dataagreement')
    .select('dataagreementid, "Short Title"');

  if (error) {
    console.error('Error fetching data agreements:', error.message);
    return [];
  }

  return data;
};
