// admin_elements/RatesPanel.jsx

import React, { useState, useEffect } from 'react';
import {
  fetchRatesByDataAgreementAndTradeperson,
  insertRate,
  updateRate,
  deleteRate,
} from '../services/api';
import styles from '../styles/RatesPanel.module.css';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root'); // Adjust if your root element has a different ID

const RatesPanel = ({ dataAgreementId, tradepersonId }) => {
    const [rates, setRates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [ratesPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

  // States for CRUD operations
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('insert'); // 'insert' or 'update'
  const [selectedRate, setSelectedRate] = useState(null);
  const [formData, setFormData] = useState({
    rate_name: '',
    rate_value: '',
  });
  const [operationMessage, setOperationMessage] = useState('');

  const loadRates = async () => {
    setLoading(true);
    setError('');
    try {
      const data = await fetchRatesByDataAgreementAndTradeperson(dataAgreementId, tradepersonId);
      setRates(data);
    } catch (err) {
      setError('Failed to fetch rates.');
      console.error('Error fetching rates:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataAgreementId && tradepersonId) {
      loadRates();
    } else {
      setRates([]);
    }
  }, [dataAgreementId, tradepersonId]);

  useEffect(() => {
    loadRates();
  }, [dataAgreementId, tradepersonId]);

  // Get current rates
  const indexOfLastRate = currentPage * ratesPerPage;
  const indexOfFirstRate = indexOfLastRate - ratesPerPage;
  const currentRates = rates.slice(indexOfFirstRate, indexOfLastRate);

  const totalPages = Math.ceil(rates.length / ratesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Open modal for insert or update
  const openModal = (type, rate = null) => {
    setModalType(type);
    if (type === 'update') {
      setSelectedRate(rate);
      setFormData({
        rate_name: rate.rate_name || '',
        rate_value: rate.rate_value || '',
      });
    } else {
      setSelectedRate(null);
      setFormData({
        rate_name: '',
        rate_value: '',
      });
    }
    setIsModalOpen(true);
    setOperationMessage('');
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRate(null);
    setFormData({
      rate_name: '',
      rate_value: '',
    });
    setOperationMessage('');
  };

  // Handle form submission for insert or update
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalType === 'insert') {
      // Insert new rate
      try {
        const result = await insertRate({
          rate_name: formData.rate_name,
          rate_value: parseFloat(formData.rate_value),
          dataagreementid: dataAgreementId,
          tradepersonid: tradepersonId,
        });
        if (result.success) {
          setOperationMessage(result.message);
          loadRates();
          closeModal();
        } else {
          setOperationMessage(result.message);
        }
      } catch (err) {
        setOperationMessage('An unexpected error occurred.');
        console.error('Error inserting rate:', err);
      }
    } else if (modalType === 'update') {
      // Update existing rate
      try {
        const result = await updateRate(selectedRate.rate_id, {
          rate_name: formData.rate_name,
          rate_value: parseFloat(formData.rate_value),
        });
        if (result.success) {
          setOperationMessage(result.message);
          loadRates();
          closeModal();
        } else {
          setOperationMessage(result.message);
        }
      } catch (err) {
        setOperationMessage('An unexpected error occurred.');
        console.error('Error updating rate:', err);
      }
    }
  };

  // Handle delete rate
  const handleDelete = async (rateId) => {
    if (window.confirm('Are you sure you want to delete this rate?')) {
      try {
        const result = await deleteRate(rateId);
        if (result.success) {
          setOperationMessage(result.message);
          loadRates();
        } else {
          setOperationMessage(result.message);
        }
      } catch (err) {
        setOperationMessage('An unexpected error occurred.');
        console.error('Error deleting rate:', err);
      }
    }
  };

  if (!dataAgreementId || !tradepersonId) {
    return <div className={styles.noData}>Please select a Data Agreement and Tradeperson.</div>;
  }

  if (loading) {
    return <div className={styles.loading}>Loading rates...</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  if (rates.length === 0) {
    return <div className={styles.noData}>No rates available for the selected Data Agreement and Tradeperson.</div>;
  }

  return (
    <div className={styles.ratesPanel}>
      <h2>Rates</h2>
      <button onClick={() => openModal('insert')} className={styles.addButton}>
        Add New Rate
      </button>
      <table className={styles.ratesTable}>
        <thead>
          <tr>
            {Object.keys(currentRates[0]).map((header) => (
              <th key={header}>{header.replace(/_/g, ' ').toUpperCase()}</th>
            ))}
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {currentRates.map((rate) => (
            <tr key={rate.rate_id}>
              {Object.entries(rate).map(([key, value]) => (
                <td key={key}>
                  {value !== null && value !== undefined ? value.toString() : 'N/A'}
                </td>
              ))}
              <td>
                <button
                  onClick={() => openModal('update', rate)}
                  className={styles.editButton}
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(rate.rate_id)}
                  className={styles.deleteButton}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className={styles.pagination}>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => paginate(i + 1)}
            className={`${styles.pageButton} ${
              currentPage === i + 1 ? styles.active : ''
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>

      {/* Modal for Insert/Update */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={modalType === 'insert' ? 'Add New Rate' : 'Update Rate'}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        closeTimeoutMS={200}
      >
        <h2>{modalType === 'insert' ? 'Add New Rate' : 'Update Rate'}</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="rate_name">Rate Name</label>
            <input
              type="text"
              id="rate_name"
              name="rate_name"
              value={formData.rate_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="rate_value">Rate Value</label>
            <input
              type="number"
              step="0.01"
              id="rate_value"
              name="rate_value"
              value={formData.rate_value}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.modalActions}>
            <button type="submit" className={styles.submitButton}>
              {modalType === 'insert' ? 'Add Rate' : 'Update Rate'}
            </button>
            <button type="button" onClick={closeModal} className={styles.cancelButton}>
              Cancel
            </button>
          </div>
        </form>
        {operationMessage && <p className={styles.operationMessage}>{operationMessage}</p>}
      </Modal>
    </div>
  );
};

export default RatesPanel;
