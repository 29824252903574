// src/pages/Login.jsx
import React, { useState, useContext } from 'react';
import { supabase } from '../services/supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const REDIRECT_URL_DASHBOARD =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_REDIRECT_URL
    : 'http://localhost:3000/dashboard';

const REDIRECT_URL_CHANGE_PASSWORD =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_REDIRECT_URL_CHANGE_PASSWORD
    : 'http://localhost:3000/change_password';

const Login = () => {
  const { setUser } = useContext(AuthContext); // Access setUser from AuthContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isMagicLink, setIsMagicLink] = useState(false);
  const navigate = useNavigate();

  const handleMagicLink = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: REDIRECT_URL_DASHBOARD,
        },
      });

      if (error) throw error;

      setMessage('Check your email for the magic link!');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
  
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
  
      if (error) {
        console.error('Login Error:', error.message); // Debug log login error
        throw error;
      }
  
      console.log('User ID from Auth Table:', data.user.id); // Debug log user ID
  
      // Fetch user's role from profile table
      const { data: userProfile, error: profileError } = await supabase
        .from('profile') // Ensure 'profile' table has a 'role' column
        .select('role')
        .eq('id', data.user.id)
        .single();
  
      if (profileError) {
        console.error('Error fetching user profile:', profileError.message); // Debug log profile error
        throw new Error('Could not fetch user profile.');
      }
  
      console.log('Fetched Role:', userProfile.role); // Debug log role
      setUser({ ...data.user, role: userProfile.role });
  
      // Navigate based on role
      navigate(userProfile.role === 'admin' ? '/admin' : '/dashboard');
      setMessage('Logged in successfully!');
    } catch (error) {
      setError(error.message);
      console.error('Handle Login Error:', error.message); // Debug log general error
    }
  };
  

  const handlePasswordReset = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: REDIRECT_URL_CHANGE_PASSWORD,
      });

      if (error) throw error;

      setMessage('Check your email for reset instructions!');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold text-center text-blue-700 mb-6">Login</h2>
        {message && <p className="text-green-600 text-center">{message}</p>}
        {error && <p className="text-red-600 text-center">{error}</p>}

        <div className="flex justify-center mb-6">
          <button
            onClick={() => setIsMagicLink(false)}
            className={`px-4 py-2 text-sm font-medium ${
              !isMagicLink ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
            } rounded-l-lg focus:outline-none`}
          >
            Login with Password
          </button>
          <button
            onClick={() => setIsMagicLink(true)}
            className={`px-4 py-2 text-sm font-medium ${
              isMagicLink ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
            } rounded-r-lg focus:outline-none`}
          >
            Login with Magic Link
          </button>
        </div>

        {!isMagicLink ? (
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="you@example.com"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Password"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Login
            </button>
            <p className="text-center mt-4">
              <button
                type="button"
                onClick={handlePasswordReset}
                className="text-blue-600 hover:underline"
              >
                Forgot Password?
              </button>
            </p>
          </form>
        ) : (
          <form onSubmit={handleMagicLink}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="you@example.com"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Send Magic Link
            </button>
          </form>
        )}

        <p className="text-center mt-6">
          Don't have an account?{' '}
          <Link to="/register" className="text-blue-600 hover:underline">
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
