// services/api/fetchColumnMetadata.js

import { supabase } from '../supabaseClient';

/**
 * Fetches column metadata for a specific table using the 'fetch_column_metadata' stored procedure.
 *
 * @param {string} tableName - The name of the table.
 * @returns {Promise<Array<Object>>} - Array of column metadata.
 */
export const fetchColumnMetadata = async (tableName) => {
  try {
    const { data, error } = await supabase.rpc('fetch_column_metadata', {
      schema_name: 'constructionmasteraccessexport',
      table_name: tableName,
    });

    if (error) {
      throw new Error(`Error fetching metadata for table ${tableName}: ${error.message}`);
    }
    return data;
  } catch (error) {
    console.error('Unexpected error fetching column metadata:', error);
    return [];
  }
};
