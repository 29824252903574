// src/admin_elements/ExcelUploadModal.jsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from '../styles/ExcelUploadModal.module.css';
import * as XLSX from 'xlsx';
import { FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import worksheetConfigurations from '../config/worksheetConfig';
import ParsedDataDisplay from './ParsedDataDisplay';

Modal.setAppElement('#root'); // Ensure this matches your app's root element

const ExcelUploadModal = ({ isOpen, onClose }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [parsedData, setParsedData] = useState({});
  const [errors, setErrors] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrors([]);
    setProgress(0);
    setParsedData({});
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select an Excel file to upload.');
      return;
    }

    setIsProcessing(true);
    setProgress(10);
    setErrors([]);
    setParsedData({});

    try {
      // Read the file as ArrayBuffer
      const data = await file.arrayBuffer();
      setProgress(30);

      // Parse the Excel workbook
      const workbook = XLSX.read(data, { type: 'array' });
      setProgress(50);

      const configKeys = Object.keys(worksheetConfigurations);

      // Initialize result object
      const result = {};

      // Iterate through each worksheet configuration
      for (const key of configKeys) {
        const config = worksheetConfigurations[key];
        const { tableName } = config;

        // Check if the worksheet exists in the workbook
        if (!workbook.SheetNames.includes(tableName)) {
          if (tableName !== 'data') { // 'data' worksheet is handled separately
            setErrors(prevErrors => [...prevErrors, `Missing worksheet: ${tableName}`]);
            toast.error(`Missing worksheet: ${tableName}`);
            continue; // Skip to the next worksheet
          }
        }

        if (tableName === 'data') {
          // Handle the 'data' worksheet containing multiple tables
          if (!workbook.SheetNames.includes('data')) {
            setErrors(prevErrors => [...prevErrors, `Missing worksheet: data`]);
            toast.error(`Missing worksheet: data`);
            continue;
          }

          const worksheetData = workbook.Sheets['data'];
          const jsonData = XLSX.utils.sheet_to_json(worksheetData, { defval: null });
          setProgress(prev => prev + 10); // Increment progress

          const dataConfig = worksheetConfigurations['data'];

          // Validate required columns
          const dataErrors = validateColumns(jsonData, dataConfig);
          if (dataErrors.length > 0) {
            setErrors(prevErrors => [...prevErrors, ...dataErrors]);
            toast.error('Some columns are missing or data is invalid in the data worksheet.');
            continue;
          }

          // Segregate data based on table_name
          const segregatedData = segregateDataByTable(jsonData, dataConfig);
          setProgress(prev => prev + 10); // Increment progress

          // Merge segregated data into result
          Object.keys(segregatedData).forEach(table => {
            if (!result[table]) {
              result[table] = [];
            }
            result[table] = result[table].concat(segregatedData[table]);
          });

        } else {
          // Handle separate worksheets
          const worksheetData = workbook.Sheets[tableName];
          const jsonData = XLSX.utils.sheet_to_json(worksheetData, { defval: null });
          setProgress(prev => prev + 10); // Increment progress

          // Validate required columns
          const sheetErrors = validateColumns(jsonData, config);
          if (sheetErrors.length > 0) {
            setErrors(prevErrors => [...prevErrors, ...sheetErrors]);
            toast.error(`Some columns are missing or data is invalid in worksheet: ${tableName}`);
            continue;
          }

          // Add data to result
          result[tableName] = jsonData;
        }
      }

      setProgress(90);

      setParsedData(result);
      setProgress(100);
      toast.success('Excel file processed successfully!');
    } catch (error) {
      console.error('Processing Error:', error);
      toast.error(`Processing failed: ${error.message}`);
      setProgress(0);
    } finally {
      setIsProcessing(false);
    }
  };

  // Function to validate required columns
  const validateColumns = (data, config) => {
    const sheetErrors = [];
    const requiredColumns = Object.keys(config.columns);

    if (data.length === 0) {
      sheetErrors.push(`Worksheet "${config.tableName}" is empty.`);
      return sheetErrors;
    }

    const firstRow = data[0];
    requiredColumns.forEach((col) => {
      if (!(col in firstRow)) {
        sheetErrors.push(`Worksheet "${config.tableName}" is missing column: "${col}"`);
      }
    });

    // Optional: Add more validations (e.g., data types) here

    return sheetErrors;
  };

  // Function to segregate data into different tables based on 'table_name'
  const segregateDataByTable = (data, config) => {
    const { identifierColumn, tables, dateFields } = config;
    const result = {};
    const sheetErrors = [];

    data.forEach((record, index) => {
      const tableName = record[identifierColumn];
      if (!tableName) {
        sheetErrors.push(`Row ${index + 2}: Missing "${identifierColumn}" value.`);
        return;
      }

      const tableConfig = tables[tableName];
      if (!tableConfig) {
        sheetErrors.push(`Row ${index + 2}: Unknown table name "${tableName}".`);
        return;
      }

      if (!result[tableName]) {
        result[tableName] = [];
      }

      // Process date fields
      dateFields.forEach((field) => {
        if (record[field] !== null && record[field] !== undefined) {
          if (typeof record[field] === 'number') {
            const jsDate = excelDateToJSDate(record[field]);
            record[field] = formatDate(jsDate);
          } else if (record[field] instanceof Date) {
            record[field] = formatDate(record[field]);
          }
          // If the field is already a string, you might want to verify its format or skip
        }
      });

      // Extract only relevant columns based on tableConfig
      const extractedRecord = {};
      Object.keys(tableConfig.columns).forEach((col) => {
        extractedRecord[col] = record[col];
      });

      result[tableName].push(extractedRecord);
    });

    if (sheetErrors.length > 0) {
      result.__errors__ = sheetErrors;
    }

    return result;
  };

  // Helper function to convert Excel serial number to JS Date
  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400; // seconds in a day
    const date_info = new Date(utc_value * 1000); // convert to milliseconds

    const fractional_day = serial - Math.floor(serial) + 1e-8;
    const total_seconds = Math.floor(fractional_day * 86400);

    const seconds = total_seconds % 60;
    const minutes = Math.floor(total_seconds / 60) % 60;
    const hours = Math.floor(total_seconds / 3600);

    date_info.setUTCHours(hours, minutes, seconds);

    return date_info;
  };

  // Helper function to format JS Date to "YYYY-mm-dd"
  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-indexed
    const dd = (`0${date.getDate()}`).slice(-2);
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
      setFile(null);
      setErrors([]);
      setProgress(0);
      setParsedData({});
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Upload Excel File"
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
      closeTimeoutMS={200}
    >
      <h2>Upload Excel Workbook</h2>
      <div className={styles.instructions}>
        <p>Please fill out all the columns in the Excel worksheet and do not modify anything.</p>
        <p>Download the <a href="/template_workbook.xlsx" download="template_workbook.xlsx">template here</a>.</p>
      </div>
      <div className={styles.modalBody}>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          disabled={isProcessing}
          className={styles.fileInput}
        />
        {file && <p>Selected File: {file.name}</p>}

        {errors.length > 0 && (
          <div className={styles.error}>
            <p><strong>Errors:</strong></p>
            <ul>
              {errors.map((error, idx) => (
                <li key={idx}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        {isProcessing && (
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ width: `${progress}%` }}
            ></div>
            <span>{progress}%</span>
          </div>
        )}

        {/* Display Parsed Data */}
        {Object.keys(parsedData).length > 0 && (
          <div className={styles.parsedDataContainer}>
            <h3>Parsed Data:</h3>
            {Object.entries(parsedData).map(([tableName, data]) => (
              <ParsedDataDisplay key={tableName} sheetName={tableName} data={data} />
            ))}
          </div>
        )}
      </div>
      <div className={styles.modalActions}>
        <button
          onClick={handleUpload}
          className={styles.uploadButton}
          disabled={isProcessing || !file}
        >
          <FaUpload /> {isProcessing ? 'Processing...' : 'Parse Worksheets'}
        </button>
        <button
          onClick={handleClose}
          className={styles.cancelButton}
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ExcelUploadModal;
