// src/services/api/updateRate.js

import { supabase } from '../supabaseClient';

/**
 * Updates an existing rate in the 'dim_rates' table.
 *
 * @param {number} rateId - The ID of the rate to update.
 * @param {object} updatedData - The updated rate data.
 * @returns {Promise<{ success: boolean, message: string }>}
 */
export const updateRate = async (rateId, updatedData) => {
  try {
    const { data, error } = await supabase
      .from('dim_rates')
      .update(updatedData)
      .eq('rate_id', rateId); // Assuming 'rate_id' is the primary key

    if (error) {
      console.error('Error updating rate:', error.message);
      return { success: false, message: error.message };
    }

    console.log('Rate updated successfully:', data);
    return { success: true, message: 'Rate updated successfully.' };
  } catch (err) {
    console.error('Unexpected error updating rate:', err);
    return { success: false, message: 'An unexpected error occurred.' };
  }
};
