// ----- admin_elements\AccordionTableDetails.jsx -----

import React, { useEffect, useState } from 'react';
import {
  fetchDataByDataAgreementIdAndTradepersonId,
  updateAdminRecord,
  insertAdminRecord,
  getLatestEndDate,
} from "../services/api/index.js";
import styles from '../styles/AccordionTableDetails.module.css';
import UpdateConfirmationModal from './UpdateConfirmationModal';
import InsertConfirmationModal from './InsertConfirmationModal';
import { format, parseISO } from 'date-fns';
import useUserEmail from '../hooks/useUserEmail';

const AccordionTableDetails = ({ dataAgreementId, tradepersonId }) => {
  const [accordionData, setAccordionData] = useState({});
  const [currentTradepersonId, setCurrentTradepersonId] = useState(tradepersonId);
  const [openClassifications, setOpenClassifications] = useState({});
  const [openTables, setOpenTables] = useState({});
  const userEmail = useUserEmail(); // Hook is called at the top level

  // State for update functionality
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updateField, setUpdateField] = useState(''); // 'start_date', 'end_date', 'amount', 'ci_ref', 'type'
  const [updateValue, setUpdateValue] = useState('');
  const [currentRow, setCurrentRow] = useState(null); // To track which row is being updated

  // State for insert functionality
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [insertTableName, setInsertTableName] = useState('');
  const [insertError, setInsertError] = useState('');
  const [insertSuccess, setInsertSuccess] = useState('');

  // Helper function to extract primary_key_value
  const getValueFromRow = (row, key) => {
    key = key.trim().toLowerCase();
    for (const rowKey in row) {
      if (row.hasOwnProperty(rowKey)) {
        const cleanRowKey = rowKey.trim().toLowerCase();
        if (cleanRowKey === key) {
          return row[rowKey];
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    const loadData = async () => {
      if (dataAgreementId !== undefined && tradepersonId !== undefined) {
        try {
          const data = await fetchDataByDataAgreementIdAndTradepersonId(
            dataAgreementId,
            tradepersonId
          );

          console.log('Fetched Data:', data); // Inspect the data structure

          if (!data || data.length === 0) {
            console.warn('No data returned from RPC function.');
            setAccordionData({});
            return;
          }

          // Group data by classification
          const groupedData = data.reduce((acc, item) => {
            const classification = item.classification || 'Unclassified';
            if (!acc[classification]) {
              acc[classification] = [];
            }
            acc[classification].push(item);
            return acc;
          }, {});
          setAccordionData(groupedData);
          setCurrentTradepersonId(tradepersonId); // Ensure it's stored
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        // Reset data if IDs are not provided
        setAccordionData({});
      }
    };
    loadData();
  }, [dataAgreementId, tradepersonId]);

  // Function to fetch data (used for refreshing after update or insert)
  const fetchData = async () => {
    if (dataAgreementId !== undefined && tradepersonId !== undefined) {
      try {
        const data = await fetchDataByDataAgreementIdAndTradepersonId(
          dataAgreementId,
          tradepersonId
        );

        console.log('Fetched Data for Refresh:', data); // Inspect the data structure

        if (!data || data.length === 0) {
          console.warn('No data returned from RPC function.');
          setAccordionData({});
          return;
        }

        // Group data by classification
        const groupedData = data.reduce((acc, item) => {
          const classification = item.classification || 'Unclassified';
          if (!acc[classification]) {
            acc[classification] = [];
          }
          acc[classification].push(item);
          return acc;
        }, {});
        setAccordionData(groupedData);
        // Reset open states when IDs change
        setOpenClassifications({});
        setOpenTables({});
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      // Reset data if IDs are not provided
      setAccordionData({});
    }
  };

  // Toggle Classification Accordion
  const toggleClassification = (classification) => {
    setOpenClassifications((prev) => ({
      ...prev,
      [classification]: !prev[classification],
    }));
  };

  // Toggle Table Accordion
  const toggleTable = (classification, tableName) => {
    const key = `${classification}_${tableName}`;
    setOpenTables((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Handle Update Button Click
  const handleUpdateClick = (field, row) => {
    console.log('Selected Row for Update:', row);

    setUpdateField(field);
    setUpdateValue(row[field] || '');
    setCurrentRow(row); // row now includes table_name and primary_key_value
    setShowConfirmation(true);
    setUpdateError('');
    setUpdateSuccess('');
  };

  // Handle Insert Button Click
  const handleInsertClick = (tableName) => {
    console.log('Insert button clicked for table:', tableName);
    setInsertTableName(tableName);
    setShowInsertModal(true);
    setInsertError('');
    setInsertSuccess('');
  };

  // Handle Confirmation Submission for Update
  const handleConfirmUpdate = async (field, newValue) => {
    setShowConfirmation(false);
    setIsUpdating(true);
    setUpdateError('');
    setUpdateSuccess('');

    if (!currentRow) {
      setUpdateError('No row selected for update.');
      setIsUpdating(false);
      return;
    }

    const { table_name, primary_key_value } = currentRow;

    if (!table_name || primary_key_value === undefined || primary_key_value === null) {
      console.error('Missing table information or primary key value in the current row:', currentRow);
      setUpdateError('Missing table information or primary key value.');
      setIsUpdating(false);
      return;
    }

    const actualTradepersonId = currentTradepersonId; // Retrieve from state

    // For 'type', we need to ensure the value is a valid 'typeid'
    let columnToUpdate = field;
    let valueToUpdate = newValue;

    if (field === 'typename' || field === 'type') {
      columnToUpdate = 'type';
      valueToUpdate = parseInt(newValue, 10); // Convert to integer
    }

    const record = {
      table_name,
      dataagreementid: dataAgreementId,
      tradepersonid: actualTradepersonId,
      primary_key_value,
      column_to_update: columnToUpdate,
      new_value: valueToUpdate,
      user_email: userEmail,
    };

    console.log('Record object to be sent to updateAdminRecord:', record);

    try {
      const { success, message } = await updateAdminRecord(record);

      if (!success) {
        setUpdateError(message);
      } else {
        setUpdateSuccess(message);
        fetchData(); // Refresh data after successful update
      }
    } catch (err) {
      setUpdateError('An unexpected error occurred.');
      console.error('Update error:', err);
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle Confirmation Submission for Insert
  const handleConfirmInsert = async (newRecord) => {
    setShowInsertModal(false);
    setIsUpdating(true);
    setInsertError('');
    setInsertSuccess('');

    if (!insertTableName) {
      setInsertError('No table selected for insertion.');
      setIsUpdating(false);
      return;
    }

    const actualTradepersonId = currentTradepersonId;
    const email = userEmail;

    // Prepare the parameters for insertion
    const params = {
      table_name: insertTableName,
      dataagreementid: dataAgreementId,
      tradepersonid: actualTradepersonId,
      start_date: newRecord.start_date,
      end_date: newRecord.end_date,
      amount: parseFloat(newRecord.amount),
      typeid: parseInt(newRecord.typeid, 10), // Ensure typeid is an integer
      user_email: email, // Use the email from the top-level component
    };

    console.log('Parameters for insertAdminRecord:', params);

    try {
      const { success, message } = await insertAdminRecord(params);

      if (!success) {
        setInsertError(message);
      } else {
        setInsertSuccess(message);
        fetchData(); // Refresh data after successful insert
      }
    } catch (err) {
      setInsertError('An unexpected error occurred.');
      console.error('Insert error:', err);
    } finally {
      setIsUpdating(false);
    }
  };

  if (!accordionData || Object.keys(accordionData).length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className={styles['accordion-container']}>
      {Object.entries(accordionData).map(([classification, tables]) => (
        <div key={classification} className={styles['accordion-item']}>
          <div
            className={styles['accordion-title']}
            onClick={() => toggleClassification(classification)}
          >
            {classification}
          </div>
          {openClassifications[classification] && (
            <div className={styles['accordion-content']}>
              {tables.map((table, index) => (
                <div key={index} className={styles['sub-accordion-item']}>
                  <div
                    className={styles['sub-accordion-title']}
                    onClick={() => toggleTable(classification, table.table_name)}
                  >
                    {table.modified_table_name || 'No Modified Table Name'}
                    {/* Insert Button for the Table */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent table toggle
                        handleInsertClick(table.table_name);
                      }}
                      className={styles.insertButton}
                      title="Insert"
                    >
                      Insert
                    </button>
                  </div>
                  {openTables[`${classification}_${table.table_name}`] && table.data && (
                    <div className={styles['sub-accordion-content']}>
                      {table.data.length > 0 ? (
                        <table>
                          <thead>
                            <tr>
                              {Object.keys(table.data[0]).map((header) => (
                                <th key={header}>{header}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {table.data.map((row, i) => {
                              // Extract primary_key_column from table
                              let primaryKeyColumn = table.primary_key_column.trim();

                              // Remove surrounding single quotes if present
                              if (
                                primaryKeyColumn.startsWith("'") &&
                                primaryKeyColumn.endsWith("'")
                              ) {
                                primaryKeyColumn = primaryKeyColumn.slice(1, -1);
                              }

                              // Use the helper function to get primary_key_value
                              const primaryKeyValue = getValueFromRow(row, primaryKeyColumn);

                              // Debugging logs
                              console.log('primaryKeyColumn:', primaryKeyColumn);
                              console.log('primaryKeyValue:', primaryKeyValue);

                              const enrichedRow = {
                                ...row,
                                table_name: table.table_name,
                                primary_key_column: primaryKeyColumn,
                                primary_key_value: primaryKeyValue,
                              };
                              return (
                                <tr key={i}>
                                  {Object.entries(row).map(([key, value]) => (
                                    <td key={key}>
                                      <div className={styles.cellContent}>
                                        {key === 'start_date' || key === 'end_date'
                                          ? value
                                            ? format(parseISO(value), 'yyyy-MM-dd')
                                            : 'N/A'
                                          : value !== null
                                          ? value.toString()
                                          : 'N/A'}
                                        {/* Show update button under the value for specified fields */}
                                        {['start_date', 'end_date', 'amount', 'ci_ref', 'typename', 'type'].includes(
                                          key
                                        ) && (
                                          <button
                                            onClick={() =>
                                              handleUpdateClick(
                                                key === 'typename' ? 'type' : key,
                                                enrichedRow
                                              )
                                            }
                                            className={styles.updateButtonUnder}
                                          >
                                            Update
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p>No data available for this table.</p>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {/* Update Confirmation Modal */}
      {showConfirmation && currentRow && (
        <UpdateConfirmationModal
          field={updateField}
          currentValue={currentRow[updateField]}
          newValue={updateValue}
          onConfirm={handleConfirmUpdate}
          onCancel={() => setShowConfirmation(false)}
        />
      )}

      {/* Insert Confirmation Modal */}
      {showInsertModal && (
        <InsertConfirmationModal
          tableName={insertTableName}
          dataAgreementId={dataAgreementId}
          tradepersonId={currentTradepersonId}
          onConfirm={handleConfirmInsert}
          onCancel={() => setShowInsertModal(false)}
        />
      )}

      {/* Display Update Success or Error Messages */}
      {updateSuccess && <p className={styles.updateSuccess}>{updateSuccess}</p>}
      {updateError && <p className={styles.updateError}>{updateError}</p>}

      {/* Display Insert Success or Error Messages */}
      {insertSuccess && <p className={styles.updateSuccess}>{insertSuccess}</p>}
      {insertError && <p className={styles.updateError}>{insertError}</p>}

      {/* Loading Indicator */}
      {isUpdating && <p className={styles.loading}>Processing...</p>}
    </div>
  );
};

export default AccordionTableDetails;
