// admin_elements/QuickNavigationPanel.jsx

import React, { useEffect, useState } from 'react';
import { fetchTradepersonsByDataAgreementId } from '../services/api/index';
import styles from '../styles/QuickNavigationPanel.module.css';
import ExcelUploadModal from './ExcelUploadModal'; // Import the modal
import { FaUpload } from 'react-icons/fa'; // Import upload icon

const QuickNavigationPanel = ({ dataAgreements, onSelect, onTradepersonSelect }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedDataAgreementId, setSelectedDataAgreementId] = useState(null);
  const [tradepersons, setTradepersons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (dataAgreements.length > 0) {
      const initialId = dataAgreements[currentIndex].dataagreementid;
      setSelectedDataAgreementId(initialId);
      onSelect(initialId);
      fetchTradepersons(initialId);
    }
  }, [dataAgreements, currentIndex, onSelect]);

  const fetchTradepersons = async (dataAgreementId) => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchTradepersonsByDataAgreementId(dataAgreementId);
      setTradepersons(data || []);
    } catch (error) {
      console.error('Error fetching tradepersons:', error);
      setError('Failed to fetch tradepersons. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectionChange = (e) => {
    const index = dataAgreements.findIndex(
      (item) => item.dataagreementid === parseInt(e.target.value, 10)
    );
    setCurrentIndex(index);
  };

  const handleLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleRight = () => {
    if (currentIndex < dataAgreements.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleTradepersonClick = (tradepersonId) => {
    onTradepersonSelect(tradepersonId);
  };

  const handleUploadSuccess = () => {
    // Implement any actions needed after successful upload, such as refreshing data
    // For example, you might want to call onSelect again to refresh
    onSelect(selectedDataAgreementId);
  };

  if (dataAgreements.length === 0) {
    return <p>No Data Agreements Available</p>;
  }

  return (
    <div className={styles.quickNavContainer}>
      <div className={styles.navigation}>
        <button onClick={handleLeft} disabled={currentIndex === 0} className={styles.navButton}>
          &lt;
        </button>
        <label htmlFor="dataAgreementSelect" className={styles.label}>
          Select Data Agreement:
        </label>
        <select
          id="dataAgreementSelect"
          value={dataAgreements[currentIndex]?.dataagreementid || ''}
          onChange={handleSelectionChange}
          className={styles.select}
        >
          {dataAgreements.map((item) => (
            <option key={item.dataagreementid} value={item.dataagreementid}>
              {item['Short Title']}
            </option>
          ))}
        </select>
        <button
          onClick={handleRight}
          disabled={currentIndex === dataAgreements.length - 1}
          className={styles.navButton}
        >
          &gt;
        </button>
        {/* Upload Excel Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className={styles.uploadButton}
          title="Upload Excel Workbook"
        >
          <FaUpload /> Upload Excel
        </button>
      </div>

      {/* Display Classes from Tradepersons as Tiles */}
      <div className={styles.tradepersonContainer}>
        {loading && <p>Loading tradepersons...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {!loading && !error && tradepersons.length > 0 ? (
          tradepersons.map((person) => (
            <div
              key={person.tradepersonid}
              className={styles.tradepersonTile}
              onClick={() => handleTradepersonClick(person.tradepersonid)}
            >
              <p>{person.Class || `Class ${person.tradepersonid}`}</p>
            </div>
          ))
        ) : (
          !loading && !error && <p className={styles.noTradepersons}>No classes associated.</p>
        )}
      </div>

      {/* Excel Upload Modal */}
      <ExcelUploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUploadSuccess={handleUploadSuccess}
      />
    </div>
  );
};

export default QuickNavigationPanel;
