import React, { useState, useEffect } from 'react';
import Select from 'react-select';

/**
 * Multi-Level DateSelector component for selecting a year first, then quarters.
 * @param {Function} onChange - Callback to handle selected quarters.
 */
const MultiLevelDateSelector = ({ onChange }) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [quarterOptions, setQuarterOptions] = useState([]);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i); // Last 5 years

  const yearOptions = years.map((year) => ({
    value: year,
    label: year.toString(),
  }));

  useEffect(() => {
    // Whenever a year is selected, generate the quarter options for that year
    if (selectedYear) {
      const quarterStartDates = ['01-01', '04-01', '07-01', '10-01']; // Start dates of quarters
      const quarters = quarterStartDates.map((start, index) => ({
        value: `${selectedYear}-${start}`, // Create 'YYYY-MM-DD'
        label: `Q${index + 1} ${selectedYear}`, // Label for the quarter
      }));
      setQuarterOptions(quarters); // Set quarter options for the selected year
    }
  }, [selectedYear]);

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption ? selectedOption.value : null); // Set the selected year
    setQuarterOptions([]); // Reset quarter options when a new year is selected
    onChange([]); // Clear any previously selected quarters
  };

  const handleQuarterChange = (selectedOptions) => {
    const selectedQuarters = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    onChange(selectedQuarters); // Pass selected quarters back to the parent component
  };

  return (
    <div>
      {/* Year Selector */}
      <Select
        options={yearOptions} // Year options
        onChange={handleYearChange}
        placeholder="Select Year"
        isClearable // Allow clearing the year selection
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
        }}
      />

      {/* Quarter Selector */}
      {selectedYear && (
        <Select
          options={quarterOptions} // Quarters for the selected year
          isMulti
          onChange={handleQuarterChange}
          placeholder="Select Quarter(s)"
          styles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
              marginTop: '10px', // Add some margin between the year and quarter selectors
            }),
          }}
        />
      )}
    </div>
  );
};

export default MultiLevelDateSelector;
