// services/api/fetchTableRows.js

import { supabase } from '../supabaseClient';

/**
 * Fetches rows for a specific table.
 *
 * @param {string} tableName - The name of the table.
 * @returns {Promise<Array<Object>>} - Array of table rows.
 */
export const fetchTableRows = async (tableName) => {
  try {
    const { data, error } = await supabase.from(tableName).select('*');

    if (error) {
      throw new Error(`Error fetching rows for table ${tableName}: ${error.message}`);
    }
    return data;
  } catch (error) {
    console.error('Unexpected error fetching table rows:', error);
    return [];
  }
};
