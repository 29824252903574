// src/pages/Register.jsx
import React, { useState, useContext, useEffect } from 'react';
import { supabase } from '../services/supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const REDIRECT_URL_VERIFIED =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERIFICATION_REDIRECT_URL || 'https://cmcaus.net/verified'
    : 'http://localhost:3000/verified';

const Register = () => {
  // State variables for form inputs and feedback messages
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [message, setMessage] = useState(''); // Feedback message
  const [messageType, setMessageType] = useState(''); // 'error' or 'success'
  const [loading, setLoading] = useState(false); // Loading state for the signup process

  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);

  // Redirect to dashboard if the user is already logged in
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  // Handler for the registration form submission
  const handleRegister = async (e) => {
    e.preventDefault();
    setMessage('');
    setMessageType('');
    setLoading(true);

    try {
      // Attempt to sign up the user with Supabase
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: REDIRECT_URL_VERIFIED, // Pass the unencoded URL
        },
      });
      if (error) {
        console.error('Signup Error:', error);
        // Handle specific error cases based on the error message
        if (error.message.includes('duplicate key value')) {
          // Supabase returns an error message containing 'duplicate key value' when email is already in use
          setMessage('An account with this email already exists. Please log in.');
        } else if (error.message.includes('The email address is already in use')) {
          // Another possible error message for duplicate emails
          setMessage('This email is already registered. Please log in.');
        } else {
          // Generic error message for other signup errors
          setMessage(`Error: ${error.message}`);
        }
        setMessageType('error');
      } else {
        // Signup was successful
        setMessage('Please check your email to confirm your registration.');
        setMessageType('success');
        console.log('Signup Successful:', data);
        // Clear the form inputs
        setEmail('');
        setPassword('');
        // Optionally, navigate to a "Verification Pending" page
        // navigate('/verification-pending');
      }
    } catch (err) {
      // Handle unexpected errors
      console.error('Unexpected error during signup:', err);
      setMessage('An unexpected error occurred. Please try again.');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center text-blue-600">Register</h2>

        {/* Feedback Message */}
        {message && (
          <p
            className={`text-center mt-4 ${
              messageType === 'error' ? 'text-red-500' : 'text-green-500'
            }`}
          >
            {message}
          </p>
        )}

        {/* Registration Form */}
        <form onSubmit={handleRegister} className="mt-6">
          {/* Email Field */}
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="you@example.com"
            />
          </div>

          {/* Password Field */}
          <div className="mb-6">
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Your Password"
              minLength={6} // Enforce a minimum password length
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading} // Disable button while loading
            className={`w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>

        {/* Navigation Link to Login */}
        <p className="text-center mt-4">
          Already have an account?{' '}
          <Link to="/login" className="text-blue-500 hover:underline">
            Login here
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Register;
