// services/api/getLatestEndDate.js

import { supabase } from '../supabaseClient';

/**
 * Fetches the latest end_date from a specific table using the 'get_latest_end_date' stored procedure.
 *
 * @param {string} tableName - The actual table_name.
 * @param {number} tradepersonId - The Tradeperson ID.
 * @param {number} dataAgreementId - The Data Agreement ID.
 * @returns {Promise<Date|null>} - The latest end_date or null if no records exist.
 */
export const getLatestEndDate = async (tableName, tradepersonId, dataAgreementId) => {
  try {
    const { data, error } = await supabase.rpc('get_latest_end_date', {
      p_table_name: tableName,
      p_tradepersonid: tradepersonId,
      p_dataagreementid: dataAgreementId,
    });

    if (error) {
      console.error(`Error fetching latest end_date for table ${tableName}:`, error.message);
      return null;
    }

    return data || null;
  } catch (err) {
    console.error('Unexpected error fetching latest end_date:', err);
    return null;
  }
};
