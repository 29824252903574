// services/api/updateAdminRecord.js

import { supabase } from '../supabaseClient';

/**
 * Updates a specific column in a table using the 'update_admin_record' stored procedure.
 *
 * @param {object} record - The record details to update.
 * @returns {Promise<{ success: boolean, message: string }>}
 */
export const updateAdminRecord = async (record) => {
  const {
    table_name,
    dataagreementid,
    tradepersonid,
    primary_key_value,
    column_to_update,
    new_value,
    user_email,
  } = record;

  if (
    !table_name ||
    dataagreementid === undefined ||
    tradepersonid === undefined ||
    primary_key_value === undefined ||
    !column_to_update ||
    new_value === undefined ||
    !user_email
  ) {
    console.error('Missing required fields in the record object.');
    return {
      success: false,
      message: 'Missing required fields for update.',
    };
  }

  console.log('updateAdminRecord called with parameters:', {
    table_name,
    dataagreementid,
    tradepersonid,
    primary_key_value,
    column_to_update,
    new_value,
    user_email,
  });

  const { data, error } = await supabase.rpc('update_admin_record', {
    p_table_name: table_name,
    p_dataagreementid: dataagreementid,
    p_tradepersonid: tradepersonid,
    p_primary_key_value: primary_key_value,
    p_column_to_update: column_to_update,
    p_new_value: new_value,
    p_user_email: user_email,
  });

  if (error) {
    console.error('Update failed:', error.message);
    return { success: false, message: error.message };
  }

  console.log('Update successful:', data);
  return { success: true, message: data };
};
