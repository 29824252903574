// services/api/fetchDataByDataAgreementIdAndTradepersonId.js

import { supabase } from '../supabaseClient';

/**
 * Fetches data by dataagreementid and tradepersonid using the 'get_filtered_data' stored procedure.
 *
 * @param {number} dataAgreementId - The Data Agreement ID.
 * @param {number} tradepersonId - The Tradeperson ID.
 * @returns {Promise<Array<Object>>} - Array of filtered data.
 */
export const fetchDataByDataAgreementIdAndTradepersonId = async (dataAgreementId, tradepersonId) => {
  try {
    const { data, error } = await supabase.rpc('get_filtered_data', {
      selected_dataagreementid: dataAgreementId,
      selected_tradepersonid: tradepersonId,
    });

    if (error) {
      console.error('Error fetching data:', error);
      return [];
    }

    return data;
  } catch (error) {
    console.error('Unexpected error fetching data:', error);
    return [];
  }
};
