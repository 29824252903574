// src/config/worksheetConfig.js

const worksheetConfigurations = {
    // Configuration for the 'data' worksheet containing multiple tables
    data: {
      tableName: 'data', // Name of the worksheet in the Excel file
      columns: {
        table_name: "string", // Identifier column to distinguish between tables
        Class: "string",
        start_date: "date",
        end_date: "date",
        amount: "number",
        type: "string",
        ci_ref: "string",
      },
      dateFields: ["start_date", "end_date"], // Columns to be treated as dates
      identifierColumn: "table_name", // Column used to identify the table for each row
  
      // Definitions for each table identified by 'table_name' within the 'data' worksheet
      tables: {
        dim_datacarptmanallowance: {
          tableName: 'dim_datacarptmanallowance',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_datadailyfares: {
          tableName: 'dim_datadailyfares',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_dataincomeprotectionperweek: {
          tableName: 'dim_dataincomeprotectionperweek',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_dataleadhand10: {
          tableName: 'dim_dataleadhand10',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_dataotimemealallowance: {
          tableName: 'dim_dataotimemealallowance',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_dataredundancycontribution: {
          tableName: 'dim_dataredundancycontribution',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_dataretentionpayment: {
          tableName: 'dim_dataretentionpayment',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_datasideprodallowance: {
          tableName: 'dim_datasideprodallowance',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_firstaidlevel3perday: {
          tableName: 'dim_firstaidlevel3perday',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
        dim_toolallowance: {
          tableName: 'dim_toolallowance',
          columns: {
            table_name: "string",
            Class: "string",
            start_date: "date",
            end_date: "date",
            amount: "number",
            type: "string",
            ci_ref: "string",
          },
        },
      },
    },
  
    // Configuration for separate worksheets
  
    fact_dataagreement: {
      tableName: 'fact_dataagreement',
      columns: {
        "Short Title": "string",
        "Long Title": "string",
        "Commencement Date": "date",
        "Expiry Date": "date",
        "Term Years": "number",
        coverage: "string",
        industry: "string",
        "Type of Agreement": "string",
        "Employer Parties": "string",
        "Union Parties": "string",
        "last_qtr": "date",
      },
      dateFields: ["Commencement Date", "Expiry Date", "last_qtr"],
    },
    
    dim_tradeperson: {
      tableName: 'dim_tradeperson',
      columns: {
        Class: "string",
        "CI Ref": "string",
      },
      dateFields: [],
    },
    
    dim_rates: {
      tableName: 'dim_rates',
      columns: {
        start_date: "date",
        end_date: "date",
        amount: "number",
        ci_ref: "string",
      },
      dateFields: ["start_date", "end_date"],
    },
    
    dim_superannuation: {
      tableName: 'dim_superannuation',
      columns: {
        start_date: "date",
        end_date: "date",
        super_rate: "number",
      },
      dateFields: ["start_date", "end_date"],
    },
    
    dim_superminpayment: {
      tableName: 'dim_superminpayment',
      columns: {
        start_date: "date",
        end_date: "date",
        minimum_payment: "number",
        ci_ref: "string",
      },
      dateFields: ["start_date", "end_date"],
    },
    
    dim_datahours: {
      tableName: 'dim_datahours',
      columns: {
        "Ord Hrs Per Week": "number",
        "Ord Hrs Per Day": "number",
      },
      dateFields: [],
    },
    
    dim_overtime: {
      tableName: 'dim_overtime',
      columns: {
        m_f_first_2_hrs: "number",
        m_f_after_2_hrs: "number",
        sat_first_2_hrs: "number",
        sat_after_2_hrs: "number",
        sun_otime: "number",
        ci_ref: "string",
      },
      dateFields: [],
    },
    
    dim_paidbreaks: {
      tableName: 'dim_paidbreaks',
      columns: {
        "m-f_morning_crib_break_ci_ref": "string",
        "m-f_morning_crib_break_hours": "number",
        "m-f_morning_crib_break_mins": "number",
        "m-f_overtime_crib_break_ci_ref": "string",
        "m-f_overtime_crib_break_hours_of_overtime": "number",
        "m-f_overtime_crib_break_hours_of_break": "number",
        "m-f_overtime_crib_break_mins": "number",
        "m-f_overtime_crib_break_agree_to_payment_in_lieu_of": "string",
        "m_f_overtime_crib_break_payment_rate": "number",
        "m-f_overtime_crib_break_mon": "number",
        "m-f_overtime_crib_break_tues": "number",
        "m-f_overtime_crib_break_wed": "number",
        "m-f_overtime_crib_break_thurs": "number",
        "m-f_overtime_crib_break_friday": "number",
        "sat_sun_pub_hol_first_crib_break_ci_ref": "string",
        "sat_sun_pub_hol_first_crib_break_hours_of_overtime": "number",
        "sat_sun_pub_hol_first_crib_break_hours_of_break": "number",
        "sat_sun_pub_hol_first_crib_break_mins": "number",
        "sat_sun_pub_hol_first_crib_break_payable_meal_break_hrs": "number",
        "sat_sun_pub_hol_second_break_ci_ref": "string",
        "sat_sun_pub_hol_second_break_hours_of_overtime": "number",
        "sat_sun_pub_hol_second_break_hours_of_break": "number",
        "sat_sun_pub_hol_second_break_mins": "number",
        "sat_sun_pub_hol_second_break_payable_meal_break_hrs": "number",
        "sat_sun_pub_hol_second_break_rate": "number",
      },
      dateFields: [],
    },
    
    fact_leave: {
      tableName: 'fact_leave',
      columns: {
        personal_leave_days: "number",
        annual_leave_days: "number",
        annual_leave_loading: "number",
        payout_on_termination: "number",
      },
      dateFields: [],
    },
  };
  
  export default worksheetConfigurations;
  