// admin_elements/TradepersonList.jsx

import React, { useState, useEffect } from 'react';
import styles from '../styles/TradepersonList.module.css';
import { fetchTradepersonsByDataAgreementId } from '../services/api';

const TradepersonList = ({ dataAgreementId, onTradepersonSelect }) => {
  const [tradepersons, setTradepersons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadTradepersons = async () => {
      if (dataAgreementId) {
        setLoading(true);
        try {
          const data = await fetchTradepersonsByDataAgreementId(dataAgreementId);
          setTradepersons(data || []);
        } catch (error) {
          console.error('Error fetching tradepersons:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    loadTradepersons();
  }, [dataAgreementId]);

  if (loading) {
    return <div>Loading tradepersons...</div>;
  }

  if (tradepersons.length === 0) {
    return <div>No tradepersons associated.</div>;
  }

  return (
    <div className={styles.tradepersonList}>
      <h2>Tradepersons</h2>
      <ul>
        {tradepersons.map((person) => (
          <li
            key={person.tradepersonid}
            onClick={() => onTradepersonSelect(person.tradepersonid)}
          >
            {person.Class || `Tradeperson ${person.tradepersonid}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TradepersonList;
