// services/api/fetchFactDataAgreement.js

import { fetchTableRows } from './fetchTableRows';

/**
 * Fetches all data from 'fact_dataagreement'.
 *
 * @returns {Promise<Array<Object>>} - Array of data agreements.
 */
export const fetchFactDataAgreement = async () => {
  return fetchTableRows('fact_dataagreement');
};
