// services/api/fetchRatesByDataAgreementAndTradeperson.js

import { supabase } from '../supabaseClient';

/**
 * Fetches rates filtered by dataAgreementId and tradepersonId.
 *
 * @param {number} dataAgreementId - The Data Agreement ID.
 * @param {number} tradepersonId - The Tradeperson ID.
 * @returns {Promise<Array<Object>>} - Array of rate objects.
 */
export const fetchRatesByDataAgreementAndTradeperson = async (dataAgreementId, tradepersonId) => {
  try {
    const { data, error } = await supabase
    .from('dim_rates')
    .select('start_date,end_date,amount,ci_ref')
    .eq('dataagreementid', dataAgreementId)
    .eq('tradepersonid', tradepersonId);

    if (error) {
      console.error('Error fetching rates:', error.message);
      throw error;
    }

    return data;
  } catch (err) {
    console.error('Unexpected error fetching rates:', err);
    throw err;
  }
};
