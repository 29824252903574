// src/services/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

// Retrieve environment variables
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

// Create a Supabase client with the default schema set to 'constructionmasteraccessexport'
export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  db: {
    schema: 'constructionmasteraccessexport', // Set your default schema here
  },
});
