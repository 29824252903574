import React, { useState } from 'react';
import { supabase } from '../services/supabaseClient';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const requestPasswordReset = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: REACT_APP_REDIRECT_URL_CHANGE_PASSWORD, // Adjust this URL
    });
    if (error) {
      console.error("Error requesting password reset:", error);
      setMessage("Error sending reset email.");
    } else {
      setMessage("Check your email for reset instructions.");
    }
  };

  return (
    <div>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={requestPasswordReset}>Request Password Reset</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPasswordPage;
