// admin_elements/LeftPanelAccordion.jsx

import React, { useState } from 'react';
import styles from '../styles/LeftPanelAccordion.module.css';
import FactDataAgreementPanel from './FactDataAgreementPanel';
import TradepersonList from './tradepersonList';
import RatesPanel from './RatesPanel';

const LeftPanelAccordion = ({
  dataAgreements,
  selectedDataAgreementId,
  onRefresh,
  dataAgreementId,
  onTradepersonSelect,
  selectedTradepersonId, // Add this prop
}) => {
  const [openSections, setOpenSections] = useState({
    dataAgreementDetails: true,
    tradepersons: false,
    ratesPanel: false,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className={styles.accordionContainer}>
      {/* Data Agreement Details Accordion */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionTitle}
          onClick={() => toggleSection('dataAgreementDetails')}
        >
          Data Agreement Details
        </div>
        {openSections.dataAgreementDetails && (
          <div className={styles.accordionContent}>
            <FactDataAgreementPanel
              dataAgreements={dataAgreements}
              selectedDataAgreementId={selectedDataAgreementId}
            />
          </div>
        )}
      </div>

      {/* Tradepersons Accordion */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionTitle}
          onClick={() => toggleSection('tradepersons')}
        >
          Tradepersons
        </div>
        {openSections.tradepersons && (
          <div className={styles.accordionContent}>
            <TradepersonList
              dataAgreementId={dataAgreementId}
              onTradepersonSelect={onTradepersonSelect}
            />
          </div>
        )}
      </div>

      {/* RatesPanel Accordion */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionTitle}
          onClick={() => toggleSection('ratesPanel')}
        >
          Rates Panel
        </div>
        {openSections.ratesPanel && (
          <div className={styles.accordionContent}>
            <RatesPanel
              dataAgreementId={selectedDataAgreementId}
              tradepersonId={selectedTradepersonId}
            />
          </div>
        )}
      </div>
    </div>
  );
};


export default LeftPanelAccordion;
