// src/pages/Contact.jsx
import React from 'react';

const Contact = () => {
  return (
    <div className="container mx-auto text-center py-16">
      <h1 className="text-4xl font-bold text-blue-900 mb-8">Contact Us</h1>
      <p className="text-lg text-gray-700">
        For any inquiries or support, feel free to reach out:
      </p>
      <p className="text-lg text-blue-800 font-bold mt-4">Email: kristian@datasolve.tech</p>
    </div>
  );
};

export default Contact;
