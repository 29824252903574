// change_password.jsx
import React, { useState, useEffect } from 'react';
import { supabase } from '../services/supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('Loading...');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);

  useEffect(() => {
    const verifyRecoveryToken = async () => {
      const queryParams = new URLSearchParams(location.search);
      const tokenFromUrl = queryParams.get('token');
      const emailFromUrl = queryParams.get('email');

      // Log local system time and extracted parameters
      const localTime = new Date();
      console.log('Local system time:', localTime.toISOString());
      console.log('Extracted token:', tokenFromUrl);
      console.log('Extracted email:', emailFromUrl);

      if (!tokenFromUrl || !emailFromUrl) {
        setMessage('Invalid or missing token or email.');
        return;
      }

      try {
        const { error } = await supabase.auth.verifyOtp({
          type: 'recovery',
          token: tokenFromUrl,
          email: emailFromUrl,
        });

        const verificationTime = new Date();
        console.log('Token verification attempted at:', verificationTime.toISOString());

        if (error) {
          console.error('Error verifying OTP:', error);

          // Differentiate errors
          if (error.message.includes('expired')) {
            setMessage(
              `The reset token has expired. Please request a new password reset. Local time: ${verificationTime.toISOString()}`
            );
          } else if (error.message.includes('invalid')) {
            setMessage('The reset token is invalid. Please request a new password reset.');
          } else {
            setMessage('An unexpected error occurred. Please try again.');
          }
        } else {
          console.log('OTP successfully verified.');
          setIsAuthenticated(true);
          setMessage('Please enter your new password.');
        }
      } catch (err) {
        console.error('Unexpected error while verifying OTP:', err);
        setMessage('An unexpected error occurred. Please try again.');
      }
    };

    verifyRecoveryToken();
  }, [location]);

  const handlePasswordReset = async () => {
    if (!newPassword) {
      setMessage('Please enter a new password.');
      return;
    }

    if (!isAuthenticated) {
      setMessage('User is not authenticated. Unable to reset password.');
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (error) {
        console.error('Error updating password:', error);
        setMessage('Failed to update password. Please try again.');
      } else {
        console.log('Password updated successfully.');
        await supabase.auth.signOut();
        setMessage('Password updated successfully. Please log in again.');
        setIsResetSuccessful(true);
      }
    } catch (error) {
      console.error('Error during password update:', error);
      setMessage('Failed to update password. Please try again.');
    }
  };

  return (
    <div>
      <h1>Change Password</h1>
      {message && <p>{message}</p>}
      {!isResetSuccessful ? (
        isAuthenticated && (
          <>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button onClick={handlePasswordReset}>Change Password</button>
          </>
        )
      ) : (
        <button onClick={() => navigate('/login')}>Go to Login</button>
      )}
    </div>
  );
};

export default ChangePasswordPage;
