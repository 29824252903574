// src/pages/Dashboard.jsx
import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { fetchChartData } from '../services/dataService';
import DataAgreementLevelSelector from '../components/DataAgreementLevelSelector';
import DateSelector from '../components/DateSelector'; // Assuming this component exists
import '../styles/Dashboard.css'; // CSS for styling

// Lazy load chart components for performance optimization
const AnnualEarningsYearChart = lazy(() => import('../components/Charts/AnnualEarningsYearChart'));
const AnnualEarningsWeekChart = lazy(() => import('../components/Charts/AnnualEarningsWeekChart'));
const LaborCostPerHourChart = lazy(() => import('../components/Charts/LaborCostPerHourChart'));
const LaborCostPerProdHourChart = lazy(() => import('../components/Charts/LaborCostPerProdHourChart'));
const LaborCostPerYearChart = lazy(() => import('../components/Charts/LaborCostPerYearChart'));

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const fetchedData = await fetchChartData();
      if (fetchedData.length === 0) {
        setError('No data available.');
      } else {
        setData(fetchedData);
      }
      setLoading(false);
    };

    getData();
  }, []);

  const getFilteredData = useMemo(() => {
    // If no selections, return all data
    if (selectedAgreements.length === 0 && selectedDates.length === 0) {
      return data;
    }

    return data.filter((item) => {
      // Check if the item's dataagreementid is selected
      const agreementMatch =
        selectedAgreements.length > 0
          ? selectedAgreements.some(
              (agreement) => agreement.dataagreementid === item.dataagreementid
            )
          : true;

      // Check if the item's quarterdate is selected
      const dateMatch =
        selectedDates.length > 0 ? selectedDates.includes(item.quarterdate) : true;

      return agreementMatch && dateMatch;
    });
  }, [data, selectedAgreements, selectedDates]);

  const handleAgreementChange = (selections) => {
    setSelectedAgreements(selections);
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  if (loading) {
    return <div>Loading Dashboard...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div className="dashboard-container">
      <h1>Earnings & Labor Cost Dashboard</h1>

      {/* Filters Section */}
      <div className="filters-section">
        <div className="filter-item">
          <label htmlFor="data-agreement-selector">Data Agreement(s) & Levels:</label>
          <DataAgreementLevelSelector onChange={handleAgreementChange} />
        </div>
        <div className="filter-item">
          <label htmlFor="date-selector">Quarter Date(s):</label>
          <DateSelector onChange={handleDateChange} />
        </div>
      </div>

      {/* Charts Section */}
      <div className="charts-section">
        <div className="chart-container">
          <Suspense fallback={<div>Loading Annual Earnings per Year Chart...</div>}>
            <AnnualEarningsYearChart data={getFilteredData} selectedAgreements={selectedAgreements} />
          </Suspense>
        </div>
        <div className="chart-container">
          <Suspense fallback={<div>Loading Annual Earnings per Week Chart...</div>}>
            <AnnualEarningsWeekChart data={getFilteredData} selectedAgreements={selectedAgreements} />
          </Suspense>
        </div>
        <div className="chart-container">
          <Suspense fallback={<div>Loading Labor Cost per Hour Chart...</div>}>
            <LaborCostPerHourChart data={getFilteredData} selectedAgreements={selectedAgreements} />
          </Suspense>
        </div>
        <div className="chart-container">
          <Suspense fallback={<div>Loading Labor Cost per Product Hour Chart...</div>}>
            <LaborCostPerProdHourChart data={getFilteredData} selectedAgreements={selectedAgreements} />
          </Suspense>
        </div>
        <div className="chart-container">
          <Suspense fallback={<div>Loading Labor Cost per Year Chart...</div>}>
            <LaborCostPerYearChart data={getFilteredData} selectedAgreements={selectedAgreements} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
