// services/api/getTypes.js

import { supabase } from '../supabaseClient';

/**
 * Fetches types from the 'dim_type' table using the 'get_types' stored procedure.
 *
 * @returns {Promise<Array<{ typeid: number, typename: string }>>}
 */
export const getTypes = async () => {
  try {
    const { data, error } = await supabase.rpc('get_types');

    if (error) {
      console.error('Error fetching types:', error.message);
      return [];
    }

    return data;
  } catch (err) {
    console.error('Unexpected error fetching types:', err);
    return [];
  }
};
