import React from 'react';
import HeroImage from '../assets/construction-illustration.svg'; // Adjust the import paths
import Icon1 from '../assets/icon1.svg';
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';

const Home = () => {
  return (
    <div className="text-center bg-blue-100 min-h-screen">
      {/* Hero Section */}
      <section className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 py-8 md:py-16"> {/* Reduced padding */}
        <div className="md:w-1/2 mb-8 md:mb-0">
          <h1 className="text-4xl md:text-5xl font-bold text-blue-900 mb-4">Construction Analytics and Reports</h1>
          <p className="text-lg text-gray-700 mb-6">
            Unlock the power of data-driven construction with our cutting-edge web app. Tailored analytics and insights for your company, helping you streamline operations, reduce costs, and maximize efficiency.
          </p>
          <a href="#get-started" className="bg-blue-800 text-white px-6 py-3 rounded-full text-lg">Get Started</a>
        </div>
        <div className="md:w-1/2">
          <img src={HeroImage} alt="Construction Analytics" className="w-full h-auto max-w-sm mx-auto" /> {/* Adjusted size */}
        </div>
      </section>

      {/* Features Section */}
      <section className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-8 py-8 md:py-12"> {/* Reduced padding */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <img src={Icon1} alt="Cost Estimation" className="w-12 mx-auto mb-4" />
          <h3 className="text-xl font-bold text-blue-800">Cost Estimation and Budgeting</h3>
          <p className="text-gray-700 mt-2">
            Our tools provide accurate cost estimation, budgeting, and financial tracking to help you keep projects on time and within budget.
          </p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <img src={Icon2} alt="Industry Insights" className="w-12 mx-auto mb-4" />
          <h3 className="text-xl font-bold text-blue-800">Industry Insights</h3>
          <p className="text-gray-700 mt-2">
            Stay ahead in the construction industry with up-to-date insights, trends, and analytics tailored to your specific needs.
          </p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <img src={Icon3} alt="Premium Access" className="w-12 mx-auto mb-4" />
          <h3 className="text-xl font-bold text-blue-800">Premium Access</h3>
          <p className="text-gray-700 mt-2">
            Unlock premium access to our comprehensive construction data and analytics. Make informed decisions with real-time insights.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Home;
