// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPage from './pages/reset_page';
import ChangePasswordPage from './pages/change_password';
import AdminPage from './pages/Admin'; // Import AdminPage
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import Verified from './pages/Verified'; // Correctly import Verified with uppercase 'V'
import NavBar from './components/NavBar';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset_page" element={<ResetPage />} />
            <Route path="/change_password" element={<ChangePasswordPage />} />
            <Route path="/verified" element={<Verified />} /> {/* Add Verified Route */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
