// ----- admin_elements\InsertConfirmationModal.jsx -----
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from '../styles/InsertConfirmationModal.module.css';
import { fetchColumnMetadata, getLatestEndDate, getTypes } from '../services/api/index';
import { format, addDays } from 'date-fns';

Modal.setAppElement('#root'); // Ensure this matches your app's root element

const InsertConfirmationModal = ({ tableName, dataAgreementId, tradepersonId, onConfirm, onCancel }) => {
  const [columns, setColumns] = useState([]);
  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
    amount: '',
    typeid: '',              // New field for typeid
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [defaultStartDate, setDefaultStartDate] = useState('');
  const [types, setTypes] = useState([]); // State to hold types

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch column metadata if needed
        const metadata = await fetchColumnMetadata(tableName);
        setColumns(metadata);

        // Fetch the latest end_date using table_name directly
        const latestEndDate = await getLatestEndDate(tableName, tradepersonId, dataAgreementId);

        if (latestEndDate) {
          // Calculate start_date as one day after latest end_date
          const calculatedStartDate = addDays(new Date(latestEndDate), 1);
          const formattedStartDate = format(calculatedStartDate, 'yyyy-MM-dd');
          setDefaultStartDate(formattedStartDate);
          setFormData(prev => ({
            ...prev,
            start_date: formattedStartDate,
          }));
        } else {
          // No existing records; set start_date to today's date
          const today = format(new Date(), 'yyyy-MM-dd');
          setDefaultStartDate(today);
          setFormData(prev => ({
            ...prev,
            start_date: today,
          }));
        }

        // Fetch types for the dropdown
        const fetchedTypes = await getTypes();
        setTypes(fetchedTypes);
      } catch (error) {
        console.error('Error loading insert modal data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [tableName, tradepersonId, dataAgreementId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.start_date) {
      newErrors.start_date = 'Start date is required.';
    }

    if (!formData.end_date) {
      newErrors.end_date = 'End date is required.';
    }

    if (!formData.amount) {
      newErrors.amount = 'Amount is required.';
    } else if (isNaN(formData.amount) || Number(formData.amount) <= 0) {
      newErrors.amount = 'Amount must be a positive number.';
    }

    if (!formData.typeid) {
      newErrors.typeid = 'Type is required.';
    }

    // Optional: Validate that start_date is before end_date on the client-side
    if (formData.start_date && formData.end_date) {
      const start = new Date(formData.start_date);
      const end = new Date(formData.end_date);
      if (start > end) {
        newErrors.end_date = 'End date must be after start date.';
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onConfirm(formData);
    }
  };

  if (loading) {
    return (
      <Modal
        isOpen={true}
        onRequestClose={onCancel}
        contentLabel="Insert New Record"
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        closeTimeoutMS={200}
      >
        <div className={styles.loadingContainer}>
          <p>Loading...</p>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      contentLabel="Insert New Record"
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
      closeTimeoutMS={200}
    >
      <h2>Insert New Record into {tableName}</h2>
      <div className={styles.modalBody}>
        <form>
          <div className={styles.formGroup}>
            <label htmlFor="start_date">Start Date</label>
            <input
              id="start_date"
              name="start_date"
              type="date"
              value={formData.start_date}
              onChange={handleInputChange}
              required
              placeholder={defaultStartDate ? `Default: ${defaultStartDate}` : ''}
            />
            {errors.start_date && <span className={styles.error}>{errors.start_date}</span>}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="end_date">End Date</label>
            <input
              id="end_date"
              name="end_date"
              type="date"
              value={formData.end_date}
              onChange={handleInputChange}
              required
              placeholder="YYYY-MM-DD"
            />
            {errors.end_date && <span className={styles.error}>{errors.end_date}</span>}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="amount">Amount</label>
            <input
              id="amount"
              name="amount"
              type="number"
              step="0.01"
              value={formData.amount}
              onChange={handleInputChange}
              required
            />
            {errors.amount && <span className={styles.error}>{errors.amount}</span>}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="typeid">Type</label>
            <select
              id="typeid"
              name="typeid"
              value={formData.typeid}
              onChange={handleInputChange}
              required
            >
              <option value="">-- Select Type --</option>
              {types.map(type => (
                <option key={type.typeid} value={type.typeid}>
                  {type.typename}
                </option>
              ))}
            </select>
            {errors.typeid && <span className={styles.error}>{errors.typeid}</span>}
          </div>
        </form>
      </div>
      <div className={styles.modalActions}>
        <button onClick={handleSubmit} className={styles.confirmButton}>
          Insert
        </button>
        <button onClick={onCancel} className={styles.cancelButton}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default InsertConfirmationModal;
