// src/services/api/insertRate.js

import { supabase } from '../supabaseClient';

/**
 * Inserts a new rate into the 'dim_rates' table.
 *
 * @param {object} rateData - The rate data to insert.
 * @param {string} rateData.rate_name - Name of the rate.
 * @param {number} rateData.rate_value - Value of the rate.
 * @returns {Promise<{ success: boolean, message: string }>}
 */
export const insertRate = async (rateData) => {
  try {
    const { data, error } = await supabase.from('dim_rates').insert([rateData]);

    if (error) {
      console.error('Error inserting rate:', error.message);
      return { success: false, message: error.message };
    }

    console.log('Rate inserted successfully:', data);
    return { success: true, message: 'Rate inserted successfully.' };
  } catch (err) {
    console.error('Unexpected error inserting rate:', err);
    return { success: false, message: 'An unexpected error occurred.' };
  }
};
